export const header = () => {
    const header = document.querySelector('.header');

    if (header) {
        const hamburgerButton = header.querySelector('.header-hamburger');
        const headerMenu = header.querySelector('.header-menu');

        const handleBurgerClick = () => {
            if (hamburgerButton.classList.contains('-active')) {
                hamburgerButton.classList.remove('-active');
                headerMenu.classList.remove('-active');
                header.classList.remove('-active');
            } else {
                hamburgerButton.classList.add('-active');
                headerMenu.classList.add('-active');
                header.classList.add('-active');
            }
        };

        hamburgerButton.addEventListener('click', handleBurgerClick);

        const handleScrollEvent = () => {
            if (window.scrollY > 150) {
                header.classList.add('-scrolling');
            } else {
                header.classList.remove('-scrolling');
            }
        };

        window.addEventListener('scroll', handleScrollEvent);
        handleScrollEvent();
    }
};
